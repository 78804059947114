import Layout from '@core/Layout'
import { bytesToMegaBytes } from '@utils/bytesToMegaBytes'
import { useNotifications } from '@utils/useNotifications'
import { MIMETYPES_IMAGES } from './consts'

export default class DropZone extends Layout {
  constructor(
    selector,
    customDroppableArea = null,
    options = {
      multiple: true,
      onlyImages: false,
      button: null,
    }
  ) {
    super()

    this.$lightboxGallery = undefined
    this.options = options
    this.$selector = selector
    this.hoveredClassName = 'dropzone-hovered'
    this.$dropArea = this.$selector.find('.dropzone-box-area')
    this.customDroppableAreaSelector = customDroppableArea
    this.$customDroppableArea = customDroppableArea ? this.$document.find(customDroppableArea) : null
    this.multiple = options.multiple
    this.onlyImages = options.onlyImages
    this.inputFile = this.$selector.find('input[type=file]')
    this.previewAreaImages = '.dropzone-box-files .image-box'
    this.previewAreaFiles = '.dropzone-box-files .file-box'

    this.files = []
    this.images = []

    this.bind()
  }

  bind() {
    const $droppableArea = this.$customDroppableArea ? this.$customDroppableArea : this.$dropArea

    this.inputFile.on('change', (e) => {
      if (e.target.files.length) {
        const files = this.multiple ? Array.from(e.target.files) : [].concat(e.target.files[0])
        this.read(files)
      }
      e.target.value = ''
    })

    this.$dropArea.on('click touch', () => {
      this.inputFile.trigger('click')
    })

    $droppableArea.on('dragover', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })

    $droppableArea.on('dragenter', (e) => {
      e.stopPropagation()
      e.preventDefault()

      e.currentTarget.classList.add(this.hoveredClassName)
    })

    $droppableArea.on('dragleave', (e) => {
      if (this.$customDroppableArea) {
        if (
          !e.currentTarget.classList.contains(this.customDroppableAreaSelector.split('.')[1]) ||
          $(e.originalEvent.fromElement).is($droppableArea.parent())
        ) {
          e.currentTarget.classList.remove(this.hoveredClassName)
        }
      } else {
        e.currentTarget.classList.remove(this.hoveredClassName)
      }
    })

    $droppableArea.on('drop', (e) => {
      e.stopPropagation()
      e.preventDefault()

      if (e.originalEvent.dataTransfer.files.length) {
        const files = this.multiple
          ? Array.from(e.originalEvent.dataTransfer.files)
          : [].concat(e.originalEvent.dataTransfer.files[0])
        this.read(files)
      }
      e.currentTarget.classList.remove(this.hoveredClassName)
    })
  }

  read(files) {
    const self = this

    files.forEach((file) => {
      const fr = new FileReader()
      const mimeType = file.type

      if (bytesToMegaBytes(file.size) > 25) {
        useNotifications('error', Translator.trans('push_notification.task.comment.error413'))
      } else {
        fr.onload = function (e) {
          if (MIMETYPES_IMAGES.indexOf(mimeType) !== -1) {
            const newImageContainer = self.$selector.find(self.previewAreaImages).append(self.newImage(e.target.result))

            newImageContainer.find('.image-remove').on('click touch', (e) => {
              self.removeUpload(e.target, 'image')
            })

            if (self.$lightboxGallery) {
              self.$lightboxGallery.destroy()
            }

            self.$lightboxGallery = self.$selector.find('.image-box a').simpleLightbox({ fileExt: false })

            self.images.push(file)
          } else {
            if (self.onlyImages) {
              useNotifications('error', Translator.trans('push_notification.upload.only-images'))
            } else {
              self.$selector
                .find(self.previewAreaFiles)
                .append(self.newFile(file.name))
                .find('.file-remove')
                .on('click touch', (e) => {
                  self.removeUpload(e.target, 'file')
                })
              self.files.push(file)
            }
          }

          self.checkContainers()
        }

        fr.readAsDataURL(file)
      }
    })
  }

  removeUpload(element, type) {
    const self = this
    const $el = $(element)

    const removeItemSwitch = {
      image: () => {
        const $element = $el.closest('.preview-image')
        self.images = self.images.filter((value, i) => {
          return i !== $element.index()
        })
        $element.remove()
      },
      file: () => {
        const $element = $el.closest('.file-name')
        self.files = self.files.filter((value, i) => {
          return i !== $element.index()
        })
        $element.remove()
      },
    }

    if (Object.keys(removeItemSwitch).includes(type)) {
      removeItemSwitch[type]()
      this.checkContainers()
    } else {
      throw new Error("removeUpload method requires 'type' param.")
    }

    this.inputFile.value = ''
  }

  reset() {
    this.images = []
    this.files = []
  }

  newImage(path) {
    return `<div class="preview-image">
                <span class="image-remove"></span>
                <a href="${path}" >
                 <img src="${path}" alt="" />
                </a>
            </div>`
  }

  newFile(fileName) {
    const name = fileName.length > 20 ? `${fileName.substring(0, 20)}...` : fileName
    const ext = fileName.split('.')
    const extClassName = ext[ext.length - 1]

    return `<div class="file-name">
                <span class="file-remove"></span>
                <span class="icon-${extClassName}"></span>${name}
            </div>`
  }

  checkContainers() {
    const $filesArea = this.$selector.find(this.previewAreaFiles)
    const $imagesArea = this.$selector.find(this.previewAreaImages)

    $filesArea.toggleClass('filled', !!$filesArea.find('.file-name').length)
    $imagesArea.toggleClass('filled', !!$imagesArea.find('.preview-image').length)

    !this.multiple && this.$dropArea.toggleClass('hidden', !!$imagesArea.find('.preview-image').length)
    !this.multiple &&
      this.options.button &&
      this.options.button.prop('disabled', !$imagesArea.find('.preview-image').length)
  }
}
