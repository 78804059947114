export const MIMETYPES_IMAGES = [
  'image/jpeg',
  'image/jpg',
  'image/jfif',
  'image/pjpeg',
  'image/pjp',
  'image/bmp',
  'image/png',
  'image/gif',
  'image/tif',
  'image/apng',
  'image/ico',
  'image/cur',
]
